import "./Mint.css";

import { useState } from "react";
import { useEffect } from "react";

import { useWeb3React } from "@web3-react/core";

import ConnectWallet from "../ConnectWallet/ConnectWallet";

import { asaContract } from "../../index.js";
import { ethers } from "ethers";

function Mint(props) {
  const { active, chainId } = useWeb3React();

  const [showMint, setShowMint] = useState(false);
  const [showConnectWallet, setShowConnectWallet] = useState(false);
  const [amountToMint, setAmountToMint] = useState(1);

  function decreaseAmount() {
    if (amountToMint === 1) {
      return;
    } else {
      setAmountToMint(amountToMint - 1);
    }
  }
  function increaseAmount() {
    if (amountToMint === props.remainingAmount) {
      return;
    } else {
      setAmountToMint(amountToMint + 1);
    }
  }

  async function mint() {
    let mintBtn = document.getElementById("mintBtnId");

    mintBtn.disabled = true;

    try {
      let tx = await asaContract.mint(amountToMint, {
        value: ethers.utils.parseEther((0.16 * amountToMint).toString()),
      });

      mintBtn.innerHTML = "SENDING TX..";

      await tx.wait();

      mintBtn.innerHTML = "SUCCESS";
    } catch (e) {
      mintBtn.innerHTML = "MINT FAILED";
    }

    await new Promise((resolve) => setTimeout(resolve, 10000));

    mintBtn.innerHTML =
      Math.round(0.16 * amountToMint * 100) / 100 + " ETH | MINT";

    mintBtn.disabled = false;
  }

  useEffect(() => {
    if (active && chainId === 1) {
      setShowMint(true);
    } else {
      setShowMint(false);
    }

    if (showMint) {
      let mintBtn = document.getElementById("mintBtnId");

      mintBtn.innerHTML =
        Math.round(0.16 * amountToMint * 100) / 100 + " ETH | MINT";
    }
  });

  return (
    <div>
      {showConnectWallet ? (
        <ConnectWallet closeConnectWallet={() => setShowConnectWallet(false)} />
      ) : null}
      {showMint ? (
        <div className="mintContainer">
          <div className="mintChangeContainer">
            <button className="mintChangeBtn" onClick={() => decreaseAmount()}>
              -
            </button>
            <div className="mintAmountWrapper">
              <h3>{amountToMint}</h3>
            </div>
            <button className="mintChangeBtn" onClick={() => increaseAmount()}>
              +
            </button>{" "}
          </div>
          <button className="mintMintBtn" id="mintBtnId" onClick={() => mint()}>
            0.16 ETH | MINT
          </button>
        </div>
      ) : (
        <button
          className="mintConnectWalletBtn"
          onClick={() => {
            setShowConnectWallet(true);
          }}
        >
          CONNECT WALLET
        </button>
      )}
    </div>
  );
}

export default Mint;
