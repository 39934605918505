import "./ConnectWallet.css";

import { useEffect } from "react";

import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { InjectedConnector } from "@web3-react/injected-connector";

import { useWeb3React } from "@web3-react/core";

import { VscChromeClose } from "react-icons/vsc";

import { makeContract } from "../../index";

const CoinbaseWallet = new WalletLinkConnector({
  url: `https://eth.public-rpc.com`,
  appName: "ASA",
  supportedChainIds: [1, 3, 4, 5, 42, 56, 137],
});

const WalletConnect = new WalletConnectConnector({
  rpcUrl: `https://eth.public-rpc.com`,
  bridge: "https://bridge.walletconnect.org",
  qrcode: true,
});

const Injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42, 56, 137],
});

function ConnectWallet({ closeConnectWallet }) {
  const { activate, active, library, chainId } = useWeb3React();

  async function switchNetworkToMain() {
    try {
      await library.provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x1" }],
      });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (active) {
      makeContract(library);
      if (chainId !== 1) {
        switchNetworkToMain();
      } else if (chainId === 1) {
        makeContract(library);
        closeConnectWallet();
      }
    }
  });

  return (
    <div className="connectWalletContainer">
      <button onClick={() => activate(Injected)}>METAMASK</button>
      <button onClick={() => activate(CoinbaseWallet)}>COINBASE</button>
      <button onClick={() => activate(WalletConnect)}>WALLET CONNECT</button>
      <VscChromeClose
        className="connectWalletCloseIcon"
        onClick={closeConnectWallet}
      />
    </div>
  );
}

export default ConnectWallet;
