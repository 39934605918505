import "./MintInfo.css";

import { ethers } from "ethers";

import { useEffect, useState } from "react";

import Mint from "../Mint/Mint";

import { ASAABI } from "../../ABI/ASAABI.js";

function MintInfo() {
  const [isSoldOut, setIsSoldOut] = useState(false);
  const [remainingAmount, setRemainingAmount] = useState(2022);

  function openOpensea() {
    window.open("https://opensea.io/collection/applegreen-space-assembly/");
  }

  async function updateRemainingSupply() {
    let remainingSupply = document.getElementById("mintRemainingSupplyId");

    let provider2 = new ethers.providers.JsonRpcProvider(
      "https://rpc.ankr.com/eth"
    );

    let asaContract2 = new ethers.Contract(
      "0xa9011e8867df7dabf2b3a0a293e68efb9b82e2e7",
      ASAABI,
      provider2
    );

    let amount = await asaContract2.publicSoldAmount();
    amount = 1500 - amount;
    setRemainingAmount(amount);

    if (amount === 0) {
      setIsSoldOut(true);
      remainingSupply.innerHTML = "SOLD OUT";

      return;
    } else {
      remainingSupply.innerHTML = amount;
    }
  }

  useEffect(() => {
    updateRemainingSupply();
  }, []);

  return (
    <div className="mintInfoContainer">
      <p className="mintInfoTitleP">
        FOR PEOPLE WHO SEEK LONG-TERM GROWTH IN THE DIGITAL SPACE
      </p>
      <h2 className="mintInfoTitle">Applegreen Space Assembly Mint</h2>
      <hr />
      <p className="mintInfoDesc">
        Join our exclusive token-gated community of enthusiasts interested in
        collecting NFT's, creating sustainable business, sharing knowledge, and
        building a supporting community while exploring and utilizing WEB 3.0.
      </p>
      <div className="mintInfoInfoContainerWrap">
        <div className="mintInfoInfoContainer">
          <div className="mintInfoInfoWrapper">
            <p>Mint Price</p>
            <h3>0.16 ETH</h3>
          </div>
          <div className="mintInfoInfoWrapper">
            <p>Total Supply</p>
            <h3>2022</h3>
          </div>
          <div className="mintInfoInfoWrapper">
            <p>Remaining Supply</p>
            <h3 id="mintRemainingSupplyId">Loading..</h3>
          </div>
        </div>
        {isSoldOut ? (
          <button
            className="mintInfoOpenseaBtn"
            onClick={() => {
              openOpensea();
            }}
          >
            BUY ON OPENSEA
          </button>
        ) : (
          <Mint remainingAmount={remainingAmount} />
        )}
      </div>
    </div>
  );
}

export default MintInfo;
