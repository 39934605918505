import "./SideBar.css";

import { VscChromeClose } from "react-icons/vsc";

function SideBar({ closeSidebar }) {
  function openDiscord() {
    closeSidebar();

    window.open("https://discord.com/invite/fe7KXgJzHf");
  }

  function openOpensea() {
    closeSidebar();

    window.open("https://opensea.io/");
  }

  function goToAboutUs() {
    closeSidebar();

    let target = document.getElementById("homeAboutUsContainer");

    target.scrollIntoView({ behavior: "smooth" });
  }

  function goToContactUs() {
    closeSidebar();

    let target = document.getElementById("homeContactContainer");

    target.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <div className="sideBarContainer">
      <div className="sideBarWrapper">
        <h2 onClick={openDiscord}>DISCORD</h2>
        <h2 onClick={openOpensea}>OPENSEA</h2>
        <h2 onClick={goToAboutUs}>ABOUT US</h2>
        <h2 onClick={goToContactUs}>CONTACT</h2>
      </div>
      <VscChromeClose className="sideBarCloseIcon" onClick={closeSidebar} />
    </div>
  );
}

export default SideBar;
