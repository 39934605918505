import "./NavBar.css";

import { useState, useEffect } from "react";

import { GiHamburgerMenu } from "react-icons/gi";

import SideBar from "../SideBar/SideBar";

function NavBar() {
  const [showSidebar, setshowSidebar] = useState(false);
  const [title, setTitle] = useState("APPLEGREEN SPACE ASSEMBLY");

  function openDiscord() {
    window.open("https://discord.com/invite/fe7KXgJzHf");
  }

  function openOpensea() {
    window.open("https://opensea.io/collection/applegreen-space-assembly/");
  }

  function goToAboutUs() {
    let target = document.getElementById("homeAboutUsContainer");

    target.scrollIntoView({ behavior: "smooth" });
  }

  function goToContactUs() {
    let target = document.getElementById("homeContactContainer");

    target.scrollIntoView({ behavior: "smooth" });
  }

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 425) {
        setTitle("ASA");
      } else {
        setTitle("APPLEGREEN SPACE ASSEMBLY");
      }
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      {showSidebar ? (
        <SideBar closeSidebar={() => setshowSidebar(false)} />
      ) : (
        <div className="navBarContainer">
          <div className="navBarTitleWrapper">
            <h1 className="navBarTitle" id="navBarTitle">
              {title}
            </h1>
          </div>
          <div className="navBarItemsContainer">
            <h2 onClick={openDiscord}>DISCORD</h2>
            <h2 onClick={openOpensea}>OPENSEA</h2>
            <h2 onClick={goToAboutUs}>ABOUT US</h2>
            <h2 onClick={goToContactUs}>CONTACT</h2>
          </div>
          <GiHamburgerMenu
            className="hamburgerMenu"
            onClick={() => setshowSidebar(true)}
          />
        </div>
      )}
    </div>
  );
}

export default NavBar;
