import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";

import { ethers } from "ethers";

import { ASAABI } from "./ABI/ASAABI.js";

export var asaContract;

function getLibrary(provider) {
  return new Web3Provider(provider);
}

export function makeContract(provider) {
  asaContract = new ethers.Contract(
    "0xa9011e8867df7dabf2b3a0a293e68efb9b82e2e7",
    ASAABI,
    provider.getSigner()
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
      <App />
    </Web3ReactProvider>
  </React.StrictMode>
);
