import "./AboutUsTile.css";

function AboutUsTile(props) {
  return (
    <div className="aboutUsContainer">
      <h2>{props.title}</h2>
      <hr />
      <p>{props.description}</p>
    </div>
  );
}

export default AboutUsTile;
