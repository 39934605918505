import "./Home.css";

import { useState } from "react";

import NavBar from "../../components/NavBar/NavBar";
import MintInfo from "../../components/MintInfo/MintInfo";
import AboutUsTile from "../../components/AboutUsTile/AboutUsTile";

import Image from "../../assets/ASACard.png";

import { AiOutlineCopy } from "react-icons/ai";
import { AiOutlineCheckCircle } from "react-icons/ai";

function Home() {
  const [showCheck, setShowCheck] = useState(false);

  function copyToClipBoard() {
    navigator.clipboard.writeText("support@applegreenspace.com");

    setShowCheck(true);
  }

  function openDiscord() {
    window.open("https://discord.com/invite/fe7KXgJzHf");
  }

  return (
    <div>
      <NavBar />
      <div className="homeTopContainer">
        <MintInfo />
        <div className="homeCardContainer">
          <img src={Image} alt="ASACard.png" className="homeASACard" />
        </div>
      </div>
      <div className="homeAboutUsContainer" id="homeAboutUsContainer">
        <div className="homeAboutUsWrapper">
          <p className="homeAboutUs1P">ABOUT US</p>
          <h2 className="homeAboutUsTitle">
            Welcome to Applegreen Space Assembly
          </h2>
          <p className="homeAboutUs2P">
            A new chapter in your life is about to begin.
          </p>
          <hr />
        </div>
        <div className="homeAboutUsTileContainer">
          <AboutUsTile
            title="NFT TRADING COMMUNITY"
            description="ASA is an exclusive community working together and striving for the same goal. Our members are curios enthusiasts interested in collecting NFT’s, creating sustainable business, sharing knowledge, and building a supporting community while exploring and utilizing WEB 3.0. Every aspiring holder of the ASA-NFT will need a verification by me, Martinus Appelgreen, personally. After verification you will receive an invitation if certain criteria are met."
          />
          <AboutUsTile
            title="CRÈME DE LA CRÈME!"
            description="The profit gained from “the mint sale” (The release and the sale of the ASA-NFT), will kick-start the creation of the ASA Portfolio – a collection of the rarest and most valuable NFT’s across blockchain gaming, digital art, digital real estate, and other digital collectibles. Each Applegreen Space Asssembels NFT represents ownership of the ASA Portfolio. Meaning that you will get an upside from the growth of the portfolio."
          />
          <AboutUsTile
            title="Collaborations"
            description="Upcoming project collaborations available exclusive to the Applegreen Space Assembelsholders’ community. More on that in the future."
          />
        </div>
        {/*
        <div className="homeAboutUsTileContainer">
          <div className="homeAboutUs1Tile">
            <h2>NFT TRADING COMMUNITY</h2>
            <p>
              ASA is an exclusive community working together and striving for
              the same goal. Our members are curios enthusiasts interested in
              collecting NFT’s, creating sustainable business, sharing
              knowledge, and building a supporting community while exploring and
              utilizing WEB 3.0. Every aspiring holder of the ASA-NFT will need
              a verification by me, Martinus Appelgreen, personally. After
              verification you will receive an invitation if certain criteria
              are met.
            </p>
          </div>
          <div className="homeAboutUs2Tile">
            <h2>CRÈME DE LA CRÈME!</h2>
            <p>
              The profit gained from “the mint sale” (The release and the sale
              of the ASA-NFT), will kick-start the creation of the ASA Portfolio
              – a collection of the rarest and most valuable NFT’s across
              blockchain gaming, digital art, digital real estate, and other
              digital collectibles. Each Applegreen Space Asssembels NFT
              represents ownership of the ASA Portfolio. Meaning that you will
              get an upside from the growth of the portfolio.
            </p>
          </div>
          <div className="homeAboutUs1Tile">
            <h2>Collaborations</h2>
            <p>
              Upcoming project collaborations available exclusive to the
              Applegreen Space Assembelsholders’ community. More on that in the
              future.
            </p>
          </div>
              </div> */}
      </div>
      <div className="homeContactContainer" id="homeContactContainer">
        <div className="homeContactWrapper">
          <p className="homeContact1P">CONTACT US</p>
          <h2 className="homeContactTitle">Contact us via email or discord</h2>
          <p className="homeContact2P">
            We will reach back to you as soon as possible.
          </p>
          <hr />
        </div>
      </div>
      <div className="homeContactInfoWrapper">
        <div className="homeContactEmailWrapper">
          <p>support@applegreenspace.com</p>
          {showCheck ? (
            <AiOutlineCheckCircle
              onClick={copyToClipBoard}
              className="homeContactIcon"
              size={25}
            />
          ) : (
            <AiOutlineCopy
              onClick={copyToClipBoard}
              className="homeContactIcon"
              size={25}
            />
          )}
        </div>
        <button onClick={openDiscord}>JOIN DISCORD</button>
      </div>
      <hr className="homeLowHr" />
      <div className="homeCopyrightWrapper">
        <p className="homeCopyright">© Applegreen Space Assembly</p>
      </div>
    </div>
  );
}

export default Home;
